import Head from "next/head";
import React from "react";
import LoginComponent from "../components/Login/Login";
import { UserGuard } from "../Guard/UserGuard";

const Login: React.FC = (): JSX.Element => (
  <UserGuard>
    <Head>
      <title>Login</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content="Login page" />
    </Head>
    <LoginComponent />
  </UserGuard>
);

export default Login;
